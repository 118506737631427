import React from 'react'
import MainLayout from '../../components/MainLayout'
import QuestionContainer from '../../components/QuestionContainer'

import auth from '../../utils/auth'

const IndexPage = () => {
  if (!auth.isAuthenticated()) {
    auth.login()
    return <p>Leite zum Login weiter...</p>
  }

  return (
    <MainLayout>
      <QuestionContainer questionType="0" repeatTitle='Mehr wiederholen' trackProgress={true} />
    </MainLayout>
  )
}

export default IndexPage
